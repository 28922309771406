import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import { Heading } from "../../style/Heading";
import Link from "../../components/Link";
import { FlashMessage } from "../../components/FlashMessage";
import { Icon } from "../../components/Icon";
import SEO from "../../components/seo";

import { Button } from "../../style/Button";
import { Image } from "../../style/Image";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Text } from "../../style/Text";

import { URLS } from "../../const/const";

const ContactTemplate = ({
  data: {
    wp: {
      page: { featuredImage },
    },
  },
}) => (
  <Layout>
    <SEO title={"Formulář úspěšně odeslán"} />
    <Header variant="light" activeNav={URLS.contact} />

    <Wrapper bg="negative">
      <Container>
        <FlashMessage type="success">
          <Flex alignItems="center">
            <Icon icon="icon-check-circle" size={26} />
            <Text fontWeight={600} ml={2}>
              Formulář byl úspěšně odeslán
            </Text>
          </Flex>
        </FlashMessage>
      </Container>
    </Wrapper>

    <Wrapper>
      <Container flexDirection="column" pt={4} pb={[5, null, 6]}>
        <Flex alignItems="flex-start" width={1} flexWrap="wrap">
          <Flex width={[1, null, 1 / 2]} flexDirection="column" mb={[5, null, 5]}>
            <Heading as={"h1"} variant={"h1"} uppercase mb={4}>
              Děkujeme za váš zájem o služby KAPITOLu
            </Heading>
            <Text mb={4}>
              Vašemu vyplněnému formuláři se budeme v nejkratší možné době věnovat.
              <br />
              <br />
              Přejeme hezký den.
            </Text>

            <Box>
              <Button display="inline-block" variant="secondary" as={Link} href={URLS.home}>
                Zpět na úvodní stránku
              </Button>
            </Box>
          </Flex>

          <Flex width={[1, null, 1 / 2]}>
            <Image
              ml="auto"
              width={1}
              maxWidth={["100%", null, 545]}
              display="block"
              boxShadow="one"
              borderRadius={1}
              src={featuredImage?.sourceUrl ?? require("../../assets/images/contact/contact-form-success.jpg").default}
            />
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  </Layout>
);

export default ContactTemplate;

export const pageQuery = graphql`
  query CONTACT_FORM_SUCCESS_PAGE_QUERY {
    wp {
      page(id: "kontaktni-formular-ok", idType: URI) {
        title
        uri
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        seo {
          metaDesc
        }
      }
    }
  }
`;
